import React, { useEffect, useState } from "react";
import AzDesign from "./AzDesign";

const Bio = (props) => {
  const {
    contentType,
    setContentType
  } = props;

  const [initDesign, setInitDesign] = useState(true);

  useEffect(() => {
    setInitDesign(!initDesign);
  }, []);

  return (
    <>
      <div className="bioDiv">
        <div className="rightOfPicDiv">
          <h1>
            Danny Zachary
          </h1>
          <div className="poplTextFlexDiv">
            <p>
              Lead Engineer, Popl
              <br />
              Brooklyn
            </p>
          </div>
        </div>
        {/* <AzDesign initDesign={initDesign} /> */}
        {/* <div
          style={contentType === "map" ? { boxShadow: "10px 0px 6px 2px #09524d25 inset" } : { boxShadow: "-10px 0px 6px 2px #09524d15 inset" }}
          className="contentSwitcher"
        >
          <div
            onClick={() => setContentType("map")}
            className="contentOption"
            style={{ paddingLeft: 4 }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
              <path class="fa-secondary" style={contentType !== "map" ? { opacity: ".2" } : { opacity: ".5" }} d="M416 503V200.4c3.5-6.9 6.7-13.8 9.6-20.6c.5-1.2 1-2.5 1.5-3.7l116-46.4C558.9 123.4 576 135 576 152V422.8c0 9.8-6 18.6-15.1 22.3L416 503zM15.1 187.3l122.6-49c2.4 14.1 7.2 28.3 12.8 41.5c2.9 6.8 6.1 13.7 9.6 20.6V451.8L32.9 502.7C17.1 509 0 497.4 0 480.4V209.6c0-9.8 6-18.6 15.1-22.3zM384 255V504.3L192 449.4V255c20.5 31.3 42.3 59.6 56.2 77c20.5 25.6 59.1 25.6 79.6 0c13.9-17.4 35.7-45.7 56.2-77z" />
              <path class="fa-primary" style={contentType !== "map" ? { opacity: ".4" } : { fill: "green" }} d="M302.8 312C334.9 271.9 408 174.6 408 120C408 53.7 354.3 0 288 0S168 53.7 168 120c0 54.6 73.1 151.9 105.2 192c7.7 9.6 22 9.6 29.6 0zM288 72a40 40 0 1 1 0 80 40 40 0 1 1 0-80z" />
            </svg>
          </div>
          <div
            onClick={() => setContentType("sign")}
            className="contentOption"
            style={{ paddingRight: 4 }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path class="fa-secondary" style={contentType !== "sign" ? { opacity: ".4" } : { opacity: ".6", fill: "red" }} d="M256 0c-17.7 0-32 14.3-32 32V64h64V32c0-17.7-14.3-32-32-32zm32 480V256H224V480c0 17.7 14.3 32 32 32s32-14.3 32-32z" />
              <path class="fa-primary" style={contentType !== "sign" ? { opacity: ".2" } : null} d="M441.6 75.5l61.9 74.2c4.9 5.9 4.9 14.6 0 20.5l-61.9 74.2c-6.1 7.3-15.1 11.5-24.6 11.5H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32H417c9.5 0 18.5 4.2 24.6 11.5z" />
            </svg>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Bio;
