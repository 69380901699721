import React, { useEffect, useState } from 'react'

const BuyASign = () => {
    const [initDesign, setInitDesign] = useState(false);

    useEffect(() => {
        setInitDesign(!initDesign);
    }, []);

    return (
        <div className="signDiv">
            <div className="azDesign">
                <svg width="1385" height="auto" viewBox="0 0 1385 277" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 111.04L692.779 155.407L0.119129 66.3984L0 111.04Z"
                        fill="#FFD608"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M99.0215 12.0545L692.509 155.459L326.363 7.35156L99.0215 12.0545Z"
                        fill="#FFD608"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M508.426 11.9922L693.513 155.459L632.5 21.3141L508.426 11.9922Z"
                        fill="#FFD608"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1384.45 111.04L692.938 155.407L1384.32 66.3984L1384.45 111.04Z"
                        fill="#FFD608"

                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1288 12.4512L692.506 155.461L1066.21 17.6001L1288 12.4512Z"
                        fill="#FFD608"

                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M872 1.54883L694 155.078L755.93 1.65515L872 1.54883Z"
                        fill="#FFD608"

                    />
                    <path
                        style={
                            initDesign
                                ? { fill: "#BD3D44" }
                                : { fill: "#BD3D44" }
                        }

                        d="M690.928 121.058L649.5 22.1898L686.998 6.71273L728.998 22.1913L690.928 121.058Z"
                    />
                    <path
                        style={
                            initDesign
                                ? { fill: "#BD3D44" }
                                : { fill: "#BD3D44" }
                        }
                        stroke="white"
                        stroke-width={
                            initDesign
                                ? "0"
                                : "25"
                        }
                        fill-rule="evenodd" clip-rule="evenodd" d="M40.0556 61.5114L652.083 144.833L30.0977 9.97656L40.0556 61.5114Z"
                    />
                    <path
                        style={
                            initDesign
                                ? { fill: "#BD3D44" }
                                : { fill: "#BD3D44" }
                        }
                        stroke="white"
                        stroke-width={
                            initDesign
                                ? "0"
                                : "25"
                        }
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1328 64.5L715.601 148.856L1337.59 14L1328 64.5Z"
                    />
                    <path
                        style={
                            initDesign
                                ? { fill: "#BD3D44" }
                                : { fill: "#BD3D44" }
                        }

                        fill-rule="evenodd" clip-rule="evenodd" d="M370.402 10.004L664.926 137.109L494.251 28.6212L370.402 10.004Z"
                    />
                    <path
                        style={
                            initDesign
                                ? { fill: "#BD3D44" }
                                : { fill: "#BD3D44" }
                        }

                        fill-rule="evenodd" clip-rule="evenodd" d="M1005.27 13.1895L710.748 140.295L881.422 31.8067L1005.27 13.1895Z"
                    />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.4604 120.881L558.992 150.79L32.502 205.001L53.4604 120.881Z" fill="#28325D" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1330.53 120.855L825 150.764L1351.49 204.976L1330.53 120.855Z" fill="#28325D" />
                    <path
                        d="M694.878 79.2275L692.5 71.9098L690.122 79.2275L669.916 141.416H604.527H596.833L603.058 145.938L655.958 184.373L635.752 246.562L633.375 253.879L639.599 249.357L692.5 210.922L745.401 249.357L751.625 253.879L749.248 246.562L729.042 184.373L781.942 145.938L788.167 141.416H780.473H715.084L694.878 79.2275Z"
                        fill="url(#paint0_linear_1570_6715)"
                        stroke="white"
                        stroke-width={
                            initDesign
                                ? "8"
                                : "1"
                        }
                    />
                    <defs>
                        <linearGradient id="paint0_linear_1570_6715" x1="692.5" y1="80" x2="692.5" y2="265" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#FFD29E" />
                            <stop offset="1" stop-color="#C06800" />
                        </linearGradient>
                    </defs>
                </svg>
            </div>
            <div className="textForMPS">
                <p>
                    My name is Danny Zachary, and I am pleased to be a candidate for the Mesa Public Schools Board. As a product of Mesa Public Schools myself, I aim to bring a better understanding of how the decisions made in the boardroom are realized within the classroom.
                </p>
                <p>
                    Having been an engineer and a member of my company’s founding team, I’ve helped bring ideas and initiatives from discussion to services used by millions of people around the world. While being involved in this type of work is a great privilege, it also requires significant due diligence and responsibility. This experience has continued to provide me with valuable insights into what it takes to properly plan and strategize for complex issues.
                </p>
                <p>
                    Public education is an intricate and evolving responsibility within any community, not just Mesa’s. This responsibility is a challenge, but I welcome it into my life as I understand the consequences have a lasting impact on so many students and their eventual careers.
                </p>
                <p>
                    There is much I would like to work on for Mesa Public Schools, particularly in curriculum development and STEM advancement, however my main objective is to help manage our resources effectively in order to create new opportunities for our students and improve the quality of time spent by both students and faculty.
                </p>
                <p>
                    Please consider supporting my campaign
                </p>
                <p>
                    Thanks!
                    <br />
                    <span>Danny Zachary</span>
                </p> 
            </div>


        </div>
    )
}

export default BuyASign