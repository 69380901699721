import React from "react";
import Home from "./Home";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import "./cod/styles/App.css";
import ScrollToTop from 'react-router-scroll-top';

const App = () => {
  return (
    <Router>
      <ScrollToTop>
        <Switch>
          <Route path="/" component={Home} />
          <Route path="/:param" component={Home} />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
