import React, { useEffect, useState } from "react";
import Bio from "./Bio";
import mixpanel from "mixpanel-browser";
import MainContentContainer from "./MainContentContainer";

mixpanel.init("a9271835960df6aadf4b4e5ba121bbd0", { ignore_dnt: true });

const Home = () => {
  const [contentType, setContentType] = useState("map");

  useEffect(() => {
    mixpanel.track("DZ web page loaded");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const queryString = window.location.href || "";

    if (queryString.includes("sign")) {
      // setContentType("sign");
    }
  }, [window.location.href]);

  return (
    <div className="bigHomeDiv">
      <Bio
        contentType={contentType}
        setContentType={setContentType}
      />
      <MainContentContainer
        contentType={contentType}
        setContentType={setContentType}
      />
      <div className="bottomRowFlex">
      </div>
    </div>
  );
};

export default Home;
